import React, { useState, useEffect, useRef } from 'react';
import ReactAudioPlayer from 'react-audio-player';

const QQHits = () => {
  const [data, setData] = useState(null);
  const [visibleSongs, setVisibleSongs] = useState(20);
  const [allSongs, setAllSongs] = useState([]);
  const [selectedSongIndex, setSelectedSongIndex] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false); 
  const loaderRef = useRef(null);
  const [volume, setVolume] = useState(1); 
  const [lyrics, setLyrics] = useState([]);
  const [currentLyricIndex, setCurrentLyricIndex] = useState(0);
  const lyricsContainerRef = useRef(null);
  useEffect(() => {
    fetchDataAndRender();
    updateDocumentTitle();
    const savedVolume = localStorage.getItem('volume');
    if (savedVolume && isFinite(parseFloat(savedVolume))) { 
      setVolume(parseFloat(savedVolume));
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      threshold: 0.1,
    });
    const currentLoaderRef = loaderRef.current;
    if (currentLoaderRef) {
      observer.observe(currentLoaderRef);
    }
    return () => {
      if (currentLoaderRef) {
        observer.unobserve(currentLoaderRef);
      }
    };
  }, [loaderRef]);
  useEffect(() => {
    localStorage.setItem('volume', volume.toString());
  }, [volume]);
  useEffect(() => {
    if (isPlaying && lyrics.length > 0) {
      const timer = setInterval(() => {
        const audio = document.getElementById('audio');
        if (audio) {
          const currentTime = audio.currentTime;
          const nextLyricIndex = lyrics.findIndex(
            (lyric, index) =>
              index < lyrics.length - 1 &&
              convertTimeToSeconds(lyric.time) <= currentTime &&
              currentTime < convertTimeToSeconds(lyrics[index + 1].time)
          );
          setCurrentLyricIndex(nextLyricIndex === -1 ? 0 : nextLyricIndex);
          scrollToCurrentLyric();
        }
      }, 500);
      return () => clearInterval(timer);
    }
	  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, lyrics]);

  const scrollToCurrentLyric = () => {
    if (lyricsContainerRef.current) {
      const currentLyricElement = lyricsContainerRef.current.childNodes[currentLyricIndex];
      if (currentLyricElement) {
        currentLyricElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  const fetchDataAndRender = async () => {
    try {
      const response = await fetch('json/qqhits.json');
      const jsonData = await response.json();
      setAllSongs(jsonData.songlist);
      setData(jsonData.songlist);
	  
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  const getSingerName = (singer) => {
    return singer.map((artist) => artist.name).join(', ');
  };


  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setVisibleSongs((prevVisible) => prevVisible + 20);
    }
  };

  const handleImageClick = (index) => {
    setSelectedSongIndex(index);
    setIsPlaying(true);
  };

  const renderChart = () => {
    if (!data) return null;
    return allSongs.slice(0, visibleSongs).map((song, index) => (
      <div key={index} className="song">
        <div>
          <img
            src={song.data.image_path}
            alt={song.data.songname}
            loading="lazy"
            onClick={() => handleImageClick(index)}
            className={selectedSongIndex === index && isPlaying ? 'selected rotate' : 'song'}
          />
          <p>
            {index + 1}. {song.data.songname}
            <br />- {getSingerName(song.data.singer)}
          </p>
        </div>
      </div>
    ));
  };

  const updateDocumentTitle = () => {
    document.title = "QQmusic chart - MusicBox";
  };
  const handleSongEnd = () => {
    setSelectedSongIndex((prevIndex) => (prevIndex + 1) % allSongs.length);
  };
  const handleSongError = () => {
    setSelectedSongIndex((prevIndex) => (prevIndex + 1) % allSongs.length);
  };

  const selectedSong = allSongs[selectedSongIndex];
    const convertTimeToSeconds = (time) => {
    const [minutes, seconds] = time.split(':').map(parseFloat);
    return minutes * 60 + seconds;
  };

  useEffect(() => {
    const fetchLyrics = async () => {
      try {
        const response = await fetch(
          `https://audio.whatshub.top/lyrics/${encodeURIComponent(selectedSong.data.songname.replace(/[<>:"\\|?*]/g, '').toLowerCase())} - ${encodeURIComponent(getSingerName(selectedSong.data.singer).replace(/[<>:"\\|?*]/g, '').toLowerCase())}.lrc`
        );
        const text = await response.text();
        const lines = text.split('\n').filter((line) => line.trim() !== '');
        const parsedLyrics = lines.map((line) => {
          const [time, content] = line.split(']');
          return {
            time: time.substring(1),
            content,
          };
        });
        setLyrics(parsedLyrics);
      } catch (error) {
        console.error('Failed to fetch lyrics:', error);
        setLyrics([]);
      }
    };

    if (selectedSong) {
      fetchLyrics();
    }
  }, [selectedSong]);

  const getDisplayedLyrics = () => {
    if (lyrics.length === 0 || currentLyricIndex === -1) {
      return [];
    }

    const startIndex = Math.max(0, currentLyricIndex - 1);
    const endIndex = Math.min(lyrics.length - 1, currentLyricIndex + 1);
    return lyrics.slice(startIndex, endIndex + 1);
  };

  return (
    <div>
      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '75px', marginBottom: '150px' }}>
        {renderChart()}
      </div>
      <div ref={loaderRef}></div>
      {selectedSong && (
         <div className="loader" >
				  <div ref={lyricsContainerRef} className="lyrics-container">
      {getDisplayedLyrics().map((lyric, index) => (
        <p key={index} className={index === 1 ? 'bold-black' : 's-black'}>{lyric.content}</p>
      ))}
    </div>
          <ReactAudioPlayer
		  id="audio"
            src={`https://audio.whatshub.top/audio/${encodeURIComponent(selectedSong.data.songname.replace(/[<>:"\\|?*]/g, '').toLowerCase())} - ${encodeURIComponent(getSingerName(selectedSong.data.singer).replace(/[<>:"\\|?*]/g, '').toLowerCase())}.mp3`}


            autoPlay={isPlaying}
			volume={isFinite(volume) ? volume : 1}
            controls
            onEnded={handleSongEnd}
			onError={handleSongError}
			onVolumeChanged={(e) => setVolume(parseFloat(e.target.volume))}
          />
          <p style={{ color:'black',margin:'10px', fontWeight: 'bold',fontSize: '14px', overflowWrap: 'break-word', textAlign: 'center' }}>{selectedSong.data.songname} - {getSingerName(selectedSong.data.singer)}</p>
        </div>
      )}
    </div>
  );
};

export default QQHits;
